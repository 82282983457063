<template>
  <div class="subMenuCenter">
    <template v-if="$route.name === 'order'">
      <template v-if="order.status === '40' && $store.state.auth_grade >= 2">
        <TooltipGrayButton
          :Tooltip="deleteTooltip"
          @click="confirmDialogOpen('delete')"
          :disabled="order.status !== '40' || $store.state.isExpired"
        >
          삭제
        </TooltipGrayButton>
      </template>
      <template
        v-if="
          $store.state.auth_grade <= 2 &&
          order.status === '40' &&
          order.is_modify
        "
      >
        <GrayButton
          @click="confirmDialogOpen('delete')"
          :disabled="order.status !== '40' || $store.state.isExpired"
        >
          삭제 요청
        </GrayButton>
      </template>
      <TooltipGrayButton
        :Tooltip="newOrderTooltip"
        @click="newOrder"
        :disabled="$store.state.isExpired"
      >
        신규
      </TooltipGrayButton>
      <template v-if="order._id && order.kind !== 3">
        <TooltipGrayButton
          :Tooltip="repeatTooltip"
          @click="RepeatOrder"
          :disabled="$store.state.isExpired"
          >리피트 오더</TooltipGrayButton
        >
      </template>

      <template v-if="$store.state.auth_grade <= 2 && order.status === '10'">
        <GrayButton disabled> 삭제 요청 중 </GrayButton>
      </template>
      <template v-if="order.status === '20' && $store.state.auth_grade >= 2">
        <GrayButton
          @click="confirmDialogOpen('create')"
          :disabled="$store.state.isExpired"
        >
          승인
        </GrayButton>
      </template>

      <template v-else-if="order.status === '20'">
        <GrayButton
          v-if="$store.state.auth_grade >= 2"
          @click="confirmDialogOpen('reject')"
          :disabled="$store.state.isExpired"
        >
          반려
        </GrayButton>
      </template>
      <template v-if="order.status === '10' && $store.state.auth_grade >= 2">
        <GrayButton
          @click="confirmDialogOpen('delete')"
          :disabled="$store.state.isExpired"
        >
          삭제
        </GrayButton>
        <GrayButton
          @click="confirmDialogOpen('deleteReject')"
          :disabled="$store.state.isExpired"
        >
          반려
        </GrayButton>
      </template>
      <template
        v-if="
          $store.state.auth_grade <= 2 &&
          order.status == '40' &&
          order.is_modify
        "
      >
        <GrayButton
          @click="confirmDialogOpen('modify')"
          :disabled="$store.state.isExpired"
        >
          수정요청
        </GrayButton>
      </template>
      <template
        v-if="
          ($store.state.auth_grade >= 2 &&
            order.status == '40' &&
            order.is_modify) ||
          ($store.state.auth_grade <= 2 && order.status === '20')
        "
      >
        <GrayButton
          @click="
            order.status === '20'
              ? confirmDialogOpen('userModify')
              : confirmDialogOpen('modify')
          "
          :disabled="$store.state.isExpired"
        >
          수정
        </GrayButton>
      </template>
      <template
        v-else-if="
          !order.status &&
          order.is_modify !== false &&
          $store.state.auth_grade >= 2
        "
      >
        <GrayButton
          v-if="$store.state.auth_grade >= 2"
          @click="confirmDialogOpen('create')"
          :disabled="$store.state.isExpired"
        >
          승인
        </GrayButton>
      </template>
      <template
        v-else-if="
          !this.order.status &&
          order.is_modify !== false &&
          $store.state.auth_grade <= 2
        "
      >
        <GrayButton
          @click="confirmDialogOpen('create')"
          :disabled="$store.state.isExpired"
        >
          승인 요청
        </GrayButton>
      </template>
      <!-- 승인 반려시 -->
      <template
        v-else-if="
          this.order.status === '30' &&
          order.is_modify !== false &&
          $store.state.auth_grade <= 2
        "
      >
        <GrayButton
          @click="confirmDialogOpen('create')"
          :disabled="$store.state.isExpired"
        >
          승인 요청
        </GrayButton>
      </template>
    </template>

    <template v-if="$route.name === 'purchasecalculate'">
      <template>
        <GrayButton
          :disabled="
            this.$store.state.auth_grade <= 2 || $store.state.isExpired
          "
          @click="confirmDialogOpen('carry')"
        >
          이월
        </GrayButton>
      </template>
      <template v-if="this.$store.state.auth_grade >= 2">
        <GrayButton
          @click="confirmDialogOpen('reject')"
          :disabled="$store.state.isExpired"
        >
          반려
        </GrayButton>
      </template>
      <GrayButton
        @click="confirmDialogOpen('end')"
        :disabled="$store.state.isExpired"
      >
        {{ $store.state.auth_grade > 2 ? "마감" : "마감 요청" }}
      </GrayButton>
    </template>
    <template v-if="$route.name === 'purchasedeadline'">
      <template v-if="this.$store.state.auth_grade >= 2">
        <GrayButton
          @click="confirmDialogOpen('cancel')"
          :disabled="$store.state.isExpired"
        >
          마감 취소
        </GrayButton>
      </template>
      <template v-if="this.$store.state.auth_grade <= 2">
        <GrayButton
          @click="confirmDialogOpen('cancel')"
          :disabled="$store.state.isExpired"
        >
          마감 취소 요청
        </GrayButton>
      </template>
    </template>
    <template v-if="$route.name === 'salescalculate'">
      <template>
        <GrayButton
          :disabled="
            this.$store.state.auth_grade <= 2 || $store.state.isExpired
          "
          @click="confirmDialogOpen('carry')"
        >
          이월
        </GrayButton>
      </template>

      <template v-if="this.$store.state.auth_grade >= 2">
        <GrayButton
          @click="confirmDialogOpen('reject')"
          :disabled="$store.state.isExpired"
        >
          반려
        </GrayButton>
      </template>

      <GrayButton
        @click="confirmDialogOpen('end')"
        :disabled="$store.state.isExpired"
      >
        {{ $store.state.auth_grade > 2 ? "마감" : "마감 요청" }}
      </GrayButton>
    </template>
    <template v-if="$route.name === 'salesdeadline'">
      <div class="pl-0 pt-0 pb-0 topGreyButton">
        <template v-if="this.$store.state.auth_grade >= 2">
          <GrayButton
            @click="confirmDialogOpen('cancel')"
            :disabled="$store.state.isExpired"
          >
            마감 취소
          </GrayButton>
        </template>
        <template v-else>
          <GrayButton
            @click="confirmDialogOpen('cancel')"
            :disabled="$store.state.isExpired"
          >
            마감 취소 요청
          </GrayButton>
        </template>
      </div>
    </template>
    <template v-if="$route.name === 'claim'">
      <template v-if="$store.state.claimView === 'claim'">
        <GrayButton
          small
          outlined
          color="red"
          @click="selectSave('delete')"
          :disabled="$store.state.isExpired"
        >
          <span v-if="$store.state.auth_grade >= 2">삭제</span>
          <span v-else>삭제 요청</span>
        </GrayButton>

        <GrayButton
          small
          outlined
          color="black"
          dark
          @click="selectSave('end')"
          :disabled="$store.state.isExpired"
        >
          <span v-if="$store.state.auth_grade >= 2">케이스 종료</span>
          <span v-else>케이스 종료 요청</span>
        </GrayButton>
      </template>
    </template>
    <template v-if="$route.name === 'endClaim'">
      <GrayButton
        @click="selectSave('cancel')"
        :disabled="$store.state.isExpired"
      >
        <span>{{
          this.$store.state.auth_grade >= 2 ? "마감 취소" : "마감 취소 요청"
        }}</span>
      </GrayButton>
    </template>
    <template v-if="$route.name === 'setting'">
      <template v-for="item in navItems">
        <template v-if="item.value !== 'pay'">
          <GrayButton
            @click="navClick(item)"
            :key="item.value"
            :disabled="$store.state.isExpired"
            :width="160"
          >
            <span>
              {{ item.title }}
            </span>
          </GrayButton>
        </template>
        <template v-if="item.value === 'pay'">
          <!-- <template
            v-if="$store.state.auth_uuid === 'softmani5671@softmani.com'"
          > -->
          <GrayButton
            @click="navClick(item)"
            :key="item.value"
            :disabled="$store.state.isExpired"
            :width="160"
          >
            <span>
              {{ item.title }}
            </span>
          </GrayButton>
          <!-- </template> -->
        </template>
      </template>
      <!-- <template v-if="$store.state.auth_grade === '5'">
        <GrayButton @click="navClick({ value: 'pay' })" :width="160">
          <span> 결제 관리 </span>
        </GrayButton>
      </template> -->
    </template>
    <template v-if="$route.name === 'stock'">
      <template v-if="$store.state.stockView !== 'manu_stock'">
        <GrayButton
          @click="selectSave('end')"
          v-if="$store.state.stockSelected !== 'end'"
          :disabled="$store.state.isExpired"
        >
          <span v-if="$store.state.auth_grade >= 3">재고 마감 </span>
          <span v-else>재고 마감 요청</span>
        </GrayButton>
        <GrayButton
          @click="selectSave('cancel')"
          :disabled="$store.state.isExpired"
          v-else
        >
          <span v-if="$store.state.auth_grade >= 3">마감 취소 </span>
          <span v-else>마감 취소 요청</span>
        </GrayButton>
      </template>
      <!-- <GrayButton
        @click="stocktTypeChange()"
        :disabled="$store.state.isExpired"
      >
        <span>원단 재고 </span>
      </GrayButton>
      <GrayButton
        @click="stocktTypeChange()"
        :disabled="$store.state.isExpired"
      >
        <span>생지 재고 </span>
      </GrayButton>
      <GrayButton
        @click="stocktTypeChange()"
        :disabled="$store.state.isExpired"
      >
        <span>가공 재고 </span>
      </GrayButton> -->
    </template>
    <!-- <template
      v-if="$route.name === 'purchaseVat' || $route.name === 'salesVat'"
    >
    </template> -->
  </div>
</template>

<script>
import TooltipGrayButton from "@/components/atoms/VButton/TooltipGrayButton.vue";
import GrayButton from "@/components/atoms/VButton/GrayButton.vue";

import { mapGetters } from "vuex";
export default {
  components: {
    GrayButton,
    TooltipGrayButton,
  },
  data() {
    return {
      newOrderTooltip: "신규오더 작성 시 작성중인 내용은 사라집니다.",
      repeatTooltip:
        "해당 오더 번호로 리피트 오더번호가 매핑되며, 해당 오더의 데이터는 기본적으로 유지됩니다.",
      claimTooltip:
        "해당 오더의 클레임이 존재할 시, 해당 오더의 클레임으로 이동합니다.",
      deleteTooltip: "오더 삭제 시 삭제 된  오더는 되돌릴 수 없습니다.",
      navItems:
        this.$store.state.auth_grade >= 2
          ? [
              { title: "기초데이터 설정", value: "dataSetting" },
              // { title: "회사 설정", value: "company" },
              { title: "기본 설정", value: "input" },
              // { title: "프린트 설정", value: "print" },
              { title: "내 정보 변경", value: "account" },
              { title: "환경 설정", value: "other" },
              { title: "결제 관리", value: "pay" },
              // { title: "COLOR 정보", value: "color" },
            ]
          : [
              { title: "기초데이터 설정", value: "dataSetting" },
              { title: "기본 설정", value: "input" },
              // { title: "프린트 설정", value: "print" },
              { title: "계정 설정", value: "account" },
              { title: "환경 설정", value: "other" },
              // { title: "COLOR 정보", value: "color" },
            ],
      vatItems: [
        { title: "내수", value: "domestic" },
        { title: "수출", value: "export" },
        { title: "전체", value: "common" },
      ],

      lengthStat: false,
      ariseStat: true,
      endStat: true,
    };
  },
  computed: {
    ...mapGetters({
      order: "getOrderForm",
      purReleaseList: "getCalculates",
      purDedReleaseList: "getCalculateEnds",
      salesReleaseList: "getSalesCalculates",
      salesDedreleaseList: "getSalesCalculateEnds",
      stockList: "getStockTableList",
      claimList: "getClaimList",
      endClaimList: "getEndClaimList",
    }),
  },
  methods: {
    claimStatChange() {
      this.$store.commit(
        "setClaimView",
        this.$store.state.claimView === "claim" ? "end" : "claim",
      );
    },
    navClick(e) {
      this.$store.commit("setNavSelected", e);
    },
    orderValidate() {
      if (
        !this.order._id &&
        this.$store.state.orderList.find((x) => x.order === this.order.order)
      ) {
        this.$store.commit("setSnackBar", `중복된 ORDER NO 입니다.`);
        return false;
      }
      if (this.$store.state.company.input_order_status && !this.order.order) {
        this.$store.commit("setSnackBar", `ORDER NO를 입력해주세요.`);
        return false;
      }
      if (!this.order.client._id) {
        this.$store.commit("setSnackBar", `구매처를 선택해주세요.`);
        return false;
      }
      if (!this.order.item._id) {
        this.$store.commit("setSnackBar", `아이템을 선택해주세요.`);
        return false;
      }
      if (!this.order.order_kind) {
        this.$store.commit("setSnackBar", `오더 유형을 선택해주세요.`);
        return false;
      }
      if (
        this.order.advance_pay &&
        (!this.order.advance_date || !this.order.advance_client)
      ) {
        this.$store.commit(
          "setSnackBar",
          `선수금 입력 시 수금일, 업체는 필수입니다.`,
        );
        return false;
      }
      return true;
    },
    purReleaseValidate(type) {
      if (type === "carry" && !this.checkSelected()) {
        this.$store.commit("setSnackBar", `이월 할 건을 선택해주세요.`);
        return false;
      }
      if (type === "end" && !this.checkSelected()) {
        this.$store.commit("setSnackBar", `마감 할 건을 선택해주세요.`);
        return false;
      }
      if (type === "reject" && !this.checkSelected()) {
        this.$store.commit("setSnackBar", `반려 할 건을 선택해주세요.`);
        return false;
      }
      return true;
    },
    purDedReleaseValidate(type) {
      if (type === "cancel" && !this.checkSelected()) {
        this.$store.commit("setSnackBar", `마감 취소 할 건을 선택해주세요.`);
        return false;
      }
      return true;
    },
    salesReleaseValidate(type) {
      if (type === "carry" && !this.checkSelected()) {
        this.$store.commit("setSnackBar", `이월 할 건을 선택해주세요.`);
        return false;
      }
      if (type === "end" && !this.checkSelected()) {
        this.$store.commit("setSnackBar", `마감 할 건을 선택해주세요.`);
        return false;
      }
      if (type === "reject" && !this.checkSelected()) {
        this.$store.commit("setSnackBar", `반려 할 건을 선택해주세요.`);
        return false;
      }
      return true;
    },
    salesDedReleaseValidate(type) {
      if (type === "cancel" && !this.checkSelected()) {
        this.$store.commit("setSnackBar", `마감 취소 할 건을 선택해주세요.`);
        return false;
      }
      return true;
    },
    stockValidate() {
      if (!this.checkSelected()) {
        this.$store.commit("setSnackBar", `재고 마감 할 건을 선택해주세요`);
        return false;
      }
      return true;
    },

    checkSelected() {
      if (this.$route.name === "claim") {
        this.endStat = true;
        this.lengthStat = true;
        let selected = [];
        selected = [
          ...this.$store.state.ariseSelected,
          ...this.$store.state.passSelected,
        ];

        this.$store.state.ariseSelected.length ||
        this.$store.state.passSelected.length
          ? (this.lengthStat = true)
          : (this.lengthStat = false);

        selected.forEach((x) => {
          if (
            x.arise_checked &&
            x.arise_status !== "40" &&
            x.arise_status !== "30"
          )
            this.endStat = false;
          if (
            x.pass_checked &&
            x.pass_status !== "40" &&
            x.pass_status !== "30"
          )
            this.endStat = false;
        });
        const returnStat = this.lengthStat && this.endStat ? true : false;

        return returnStat;
      }

      if (this.$route.name === "endClaim") {
        return this.$store.state.ariseSelected.length ||
          this.$store.state.passSelected.length
          ? true
          : false;
      } else return this.$store.state.selected.length ? true : false;
    },

    endCheckSelected() {
      let selected = [];
      this.endStat = true;
      this.ariseStat = true;
      this.lengthStat = false;
      selected = [
        ...this.$store.state.ariseSelected,
        ...this.$store.state.passSelected,
      ];
      this.$store.state.ariseSelected.length ||
      this.$store.state.passSelected.length
        ? (this.lengthStat = true)
        : (this.lengthStat = false);

      // selected.forEach((x) => {
      //   this.validateEndCase(x);
      // });
      const returnStat =
        this.lengthStat && this.ariseStat && this.endStat ? true : false;
      return returnStat;
    },
    validateEndCase(claim) {
      this.endStat = false;

      if (
        claim.arise_checked &&
        (claim.arise_type === "매출" || claim.arise_type === "매입") &&
        claim.arise_passtype === 2 &&
        claim.arise_status === "00"
      )
        this.endStat = true;
      if (
        claim.arise_checked &&
        claim.arise_type === "비용" &&
        claim.arise_passtype === 2
      )
        this.endStat = true;
      if (
        claim.arise_checked &&
        claim.arise_type === "비용" &&
        claim.arise_passtype === 3 &&
        (claim.pass_type === "매출" || claim.pass_type === "매입") &&
        claim.pass_status === "00"
      )
        this.endStat = true;
      if (
        claim.arise_type !== "발행 예정" &&
        claim.pass_checked &&
        (claim.pass_type === "매출" || claim.pass_type === "매입") &&
        claim.pass_status === "00"
      )
        this.endStat = true;
    },
    saveValidate(type) {
      let validateStat = true;
      if (this.$route.name === "order") validateStat = this.orderValidate();
      if (this.$route.name === "purchasecalculate")
        validateStat = this.purReleaseValidate(type);
      if (this.$route.name === "purchasedeadline")
        validateStat = this.purDedReleaseValidate(type);
      if (this.$route.name === "salescalculate")
        validateStat = this.salesReleaseValidate(type);
      if (this.$route.name === "salesdeadline")
        validateStat = this.salesDedReleaseValidate(type);
      if (this.$route.name === "stock") validateStat = this.stockValidate(type);

      return validateStat;
    },
    saveOrderParse(type) {
      if (type === "create") {
        if (!this.order._id) {
          if (!this.$store.state.company.input_order_status)
            this.order.order = this.$store.state.newOrderNo.order;
          this.order.order_back = this.$store.state.newOrderNo.order_back;
          this.order.order_back_repeat =
            this.$store.state.newOrderNo.order_back_repeat;
        }
      }

      this.order.order_front = this.$store.state.formDateOrder;

      this.order.saveKind = type;
      const dialogTitleType = {
        create: this.$store.state.auth_grade >= 2 ? "승인" : "승인 요청",
        reject: "반려",
        deleteReject: "반려",
        delete: this.$store.state.auth_grade >= 2 ? "삭제" : "삭제 요청",
        modify: this.$store.state.auth_grade >= 2 ? "수정" : "수정 요청",
        userModify: "승인요청 수정",
      };

      this.$store.state.dialogTitle = dialogTitleType[type];
      this.$store.state.saveKind = "order";
    },

    async confirmDialogOpen(type) {
      // console.log(type);
      this.setCheckSelected();
      if (!this.saveValidate(type)) return;
      const savePage =
        this.$route.name === "purchasecalculate" ||
        this.$route.name === "purchasedeadline"
          ? "purchase"
          : this.$route.name === "salescalculate" ||
            this.$route.name === "salesdeadline"
          ? "sales"
          : "";
      //매입정산
      if (savePage === "purchase" || savePage === "sales") {
        if (
          confirm(
            `${this.$store.state.selected.length} 건을 ${
              type === "carry"
                ? "이월"
                : type === "end"
                ? `${this.$store.state.auth_grade >= 2 ? "마감" : "마감 요청"}`
                : type === "cancel"
                ? `${
                    this.$store.state.auth_grade >= 2
                      ? "마감 취소"
                      : "마감 취소 요청"
                  }`
                : "반려"
            } 하시겠습니까?`,
          )
        ) {
          this.$store.dispatch("TASK_CALCULATE_SAVE", {
            type: type,
            savePage: savePage,
            router: this.$route.name,
          });
        }
      }

      //오더
      if (this.$route.name === "order") {
        this.saveOrderParse(type);
        this.$store.commit("setDailogStatus", {
          status: true,
          kind: "confirm_dialog",
        });
      }
    },
    setCheckSelected() {
      if (this.$route.name === "claim" || this.$route.name === "endClaim") {
        const ariseSelected = [];
        const passSelected = [];
        const List =
          this.$route.name === "claim" ? this.claimList : this.endClaimList;

        List.forEach((x) => {
          if (x.arise_checked) {
            ariseSelected.push({ ...x, saveTarget: "arise" });
          }
          if (x.pass_checked) passSelected.push({ ...x, saveTarget: "pass" });
        });

        this.$store.commit("setAriseSelected", ariseSelected);
        this.$store.commit("setPassSelected", passSelected);
      } else {
        const selected = [];
        const List =
          this.$route.name === "purchasecalculate"
            ? this.purReleaseList
            : this.$route.name === "salescalculate"
            ? this.salesReleaseList
            : this.$route.name === "salesdeadline"
            ? this.salesDedreleaseList
            : this.$route.name === "stock"
            ? this.stockList
            : this.purDedReleaseList;

        List.forEach((x) => {
          if (x.checkBox) {
            selected.push(x);
          }
        });
        this.$store.commit("setSelected", selected);
      }
    },
    selectSave(type) {
      if (this.$route.name === "claim") {
        this.setCheckSelected();
        const messageType = {
          create: this.$store.state.auth_grade >= 2 ? "등록" : "등록 요청",
          reject: "반려",
          carry: "이월",
          delete: this.$store.state.auth_grade >= 2 ? "삭제" : "삭제 요청",
          end: this.$store.state.auth_grade >= 2 ? "종료" : "종료 요청",
          userModify: "승인요청 수정",
        };
        const endSnackMessege = {
          lengthStat: "종료할 오더를 선택해주세요",
          ariseStat: "전가 미완료 된 오더만 선택해주세요.",
          endStat: "케이스 종료 가능한 오더를 선택해주세요.",
        };
        if (type === "delete" && !this.checkSelected()) {
          if (!this.lengthStat) {
            this.$store.commit("setSnackBar", endSnackMessege["lengthStat"]);
            return;
          }
          if (!this.endStat) {
            this.$store.commit(
              "setSnackBar",
              "마감되지 않은 오더만 선택해주세요",
            );
            return;
          }
          return;
        }
        if (type === "end" && !this.endCheckSelected()) {
          if (!this.lengthStat) {
            this.$store.commit("setSnackBar", endSnackMessege["lengthStat"]);
            return;
          }

          if (!this.ariseStat) {
            this.$store.commit("setSnackBar", endSnackMessege["ariseStat"]);
            return;
          }

          if (!this.endStat) {
            this.$store.commit("setSnackBar", endSnackMessege["endStat"]);
            return;
          }
        }
        if (
          confirm(
            `${
              this.$store.state.ariseSelected.length +
              this.$store.state.passSelected.length
            } 건을 ${messageType[type]} 하시겠습니까?`,
          )
        ) {
          const saveTarget = {
            type: type,
          };
          this.$store.state.selected = [
            ...this.$store.state.ariseSelected,
            ...this.$store.state.passSelected,
          ];

          this.$store.dispatch("TASK_SELECT_CLAIM_SAVE", saveTarget);
        }
      } else if (this.$route.name === "stock") {
        this.setCheckSelected();
        if (!this.saveValidate(type)) return;
        if (
          confirm(
            `${this.$store.state.selected.length} 건을 ${
              this.$store.state.auth_grade >= 3 ? "마감" : "마감 요청"
            } 하시겠습니까?`,
          )
        ) {
          const saveTarget = {
            type: type,
          };

          this.$store.dispatch("TASK_SAVE_STOCK", saveTarget);
        }
      } else {
        this.setCheckSelected();
        if (!this.checkSelected()) {
          this.$store.commit("setSnackBar", `마감취소 할 오더를 선택해주세요.`);
          return;
        }
        const messageType = {
          cancel:
            this.$store.state.auth_grade >= 2 ? "마감취소" : "마감취소 요청",
        };

        if (
          confirm(
            `${
              this.$store.state.ariseSelected.length +
              this.$store.state.passSelected.length
            } 건을 ${messageType[type]} 하시겠습니까?`,
          )
        ) {
          const saveTarget = {
            type: type,
          };
          this.$store.state.selected = [
            ...this.$store.state.ariseSelected,
            ...this.$store.state.passSelected,
          ];

          this.$store.dispatch("TASK_SELECT_CLAIM_SAVE", saveTarget);
        }
      }
    },
    async RepeatOrder() {
      if (this.order.kind === 3) {
        this.$store.commit("setSnackBar", `이미 리피트 된 오더입니다.`);
        return;
      }
      if (confirm("해당 오더를 리피트 하시겠습니까?")) {
        this.$set(this.order, "RepeatStatus", true);
        this.$set(this.order, "SampleStatus", false);

        this.order.originOrder = this.order.order;
        await this.$store.dispatch("NEW_REPEAT_ORDER_NO");
        this.order.order = this.$store.state.newOrderNo.order;
        this.order.order_back_repeat =
          this.$store.state.newOrderNo.order_back_repeat;

        this.$store.commit("setApprovalForm");
        this.order.status = "";
        this.order.status_modify = true;
        this.order.kind = 3;
        delete this.order._id;
      }
    },
    async newOrder() {
      if (confirm("신규 오더를 작성하시겠습니까?")) {
        await this.listCall();
        this.$store.commit("setSnackBar", `오더 폼을 초기화 하였습니다.`);
      }
    },
    initOrder() {
      this.$store.commit("setOrderForm");
      this.$store.commit("setApprovalForm");

      this.$store.dispatch("SET_DATA_FORM_BACKUP", this.order);
    },
    async listCall() {
      this.initOrder();
      await this.$store.dispatch("NEW_ORDER_NO");
      await this.$store.dispatch("SET_USE_CLIENTLIST");
      await this.$store.dispatch("SET_USE_ITEMLIST");
      await this.$store.dispatch("SET_USE_CTYPELIST");
      this.$store.dispatch("SET_ITEMLIST");
      this.$store.dispatch("SET_CTYPELIST");

      this.$store.dispatch("SET_USERLIST");
    },
    stocktTypeChange() {
      this.$store.commit("setStockView", !this.$store.state.stockView);
      this.$store.commit("setInputSetStockList");
      this.$store.commit("setOutputSetStockList");
    },
  },
};
</script>
