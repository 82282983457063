<template>
  <v-app class="noneAppComponent">
    <HeaderForm />
    <div class="gridContainer">
      <HeaderMenuDrawer />

      <router-view />

      <!-- <RequestDialog v-else>알림 기능</RequestDialog> -->
    </div>

    <Footer />

    <RequestDialog />
  </v-app>
</template>

<script>
import HeaderForm from "../components/pages/common/HeaderForm.vue";
import Footer from "../components/organisms/Footer.vue";
import HeaderMenuDrawer from "../components/atoms/Vdrawer/HeaderMenuDrawer";
import RequestDialog from "@/components/pages/requestDialog/RequestDialog.vue";

export default {
  data() {
    return {};
  },
  components: {
    HeaderForm,
    Footer,
    HeaderMenuDrawer,
    RequestDialog,
    // printDialog,
  },

  beforeDestroy() {
    // window.removeEventListener("resize", this.onResize);
  },
};
</script>
<style lang="scss" src="@/components/pages/common/scss/index.scss"></style>
