<template>
  <v-dialog
    @keypress.esc="close()"
    v-model="$store.state.requestFormDialog"
    max-width="1500"
  >
    <v-card class="pr-0 pl-0">
      <v-card-text style="padding: 0px">
        <div
          class="grey lighten-2"
          style="
            height: 50px;
            width: 100%;
            border-bottom: thin solid rgba(0, 0, 0, 0.1);
            display: flex;
          "
        >
          <div
            style="margin-right: auto; width: 100%"
            class="d-flex align-center pl-4"
          >
            <h2>요청이력</h2>
          </div>
          <div style="margin-left: auto; padding-top: 5px; padding-right: 10px">
            <v-icon color="red" @click="close" large> mdi-close </v-icon>
          </div>
        </div>
        <div style="display: flex">
          <div class="pt-4 pl-3" style="width: 35%">
            <div class="requestListGrid headerGreyBorder">
              <div
                class="reqeustListGridHeader"
                v-for="col in header"
                :key="col.value"
              >
                <div
                  v-if="col.value === 'view'"
                  class="reqeustListGridHeaderRight"
                >
                  {{ col.text }}
                </div>
                <div v-else class="reqeustListGridHeaderBody">
                  {{ col.text }}
                </div>
              </div>
            </div>

            <overlay-scrollbars
              :options="{
                overflowBehavior: {
                  x: 'hidden',
                },
              }"
            >
              <div style="width: 100%; max-height: 60vh">
                <template v-if="requestList.length">
                  <div
                    class="requestListGrid bodyGreyBorder"
                    v-for="(item, i) in requestList"
                    :key="i"
                    :class="{
                      selectedRow: item.approvalId === requestForm.approvalId,
                    }"
                    @click="setForm(item)"
                  >
                    <div
                      v-for="col in header"
                      :key="col.value"
                      class="reqeustListGridBody"
                    >
                      <div class="">
                        <div
                          v-if="col.value === 'view'"
                          class="reqeustListGridBodyRight"
                        >
                          {{
                            item.confirm_status && item.confirm_status === true
                              ? "승인"
                              : item.confirm_status === false
                              ? "반려"
                              : ""
                          }}
                        </div>
                        <div v-else class="reqeustListGridBodyLeft">
                          {{ item[col.value] ? item[col.value] : "" }}
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </overlay-scrollbars>
          </div>
          <div
            style="
              min-height: calc(100vh - 200px);
              padding-top: 10px;
              padding-left: 10px;
              width: 100%;
            "
          >
            <manufactureForm v-if="requestForm.name === 'manufacture'" />
            <releaseForm v-if="requestForm.name === 'release'" />
            <orderForm v-if="requestForm.name === 'order'" />
            <claimForm
              v-if="
                requestForm.name === 'claim' || requestForm.name === 'endClaim'
              "
            />

            <calculateForm
              v-if="
                requestForm.name === 'purchasecalculate' ||
                requestForm.name === 'purchasedeadline' ||
                requestForm.name === 'salescalculate' ||
                requestForm.name === 'salesdeadline'
              "
            />

            <stockForm v-if="requestForm.name === 'claim'" />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import manufactureForm from "@/components/pages/requestDialog/RequeestForm/Manufacture.vue";
import releaseForm from "@/components/pages/requestDialog/RequeestForm/release.vue";
import orderForm from "@/components/pages/requestDialog/RequeestForm/order.vue";
import claimForm from "@/components/pages/requestDialog/RequeestForm/Claim.vue";
import calculateForm from "@/components/pages/requestDialog/RequeestForm/calculate.vue";

import stockForm from "@/components/pages/requestDialog/RequeestForm/stock.vue";
export default {
  data() {
    return {
      header: [
        {
          text: "날짜",
          value: "date",
          width: "30px",
          align: "center",
          class: "userRequestHeader",
        },
        {
          text: "오더",
          value: "order",
          width: "30px",
          align: "center",
          class: "userRequestHeader",
        },
        {
          text: "발주처",
          value: "buyer",
          width: "45px",
          align: "center",
          class: "userRequestHeader",
        },
        {
          text: "문서",
          value: "kind",
          width: "20px",
          align: "center",
          class: "userRequestHeader",
        },
        {
          text: "담당자",
          value: "liaison",
          width: "20px",
          align: "center",
          class: "userRequestRight",
        },
        {
          text: "상태",
          value: "status",
          width: "30px",
          align: "center",
          class: "userRequestRight",
        },
        {
          text: "승인 여부",
          value: "confirm_status",
          width: "20px",
          align: "center",
          class: "userRequestRight",
          filterable: false,
        },
        {
          text: "확인",
          value: "view",
          width: "20px",
          align: "center",
          class: "userRequestRight",
          filterable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      requestForm: "getRequestForm",
      requestList: "getRequestApprovalList",
    }),
  },
  components: {
    manufactureForm,
    releaseForm,
    orderForm,
    claimForm,
    calculateForm,
    stockForm,
  },
  methods: {
    async close() {
      this.$store.commit("setRequestForm");
      this.$store.commit("setRequestFormDialog");
    },
    setForm(item) {
      this.$store.dispatch("SET_REQUEST_FORM_DATA", item);
    },
  },
};
</script>
<style lang="scss" src="@/components/pages/common/scss/request.scss" />
