<template>
  <footer class="footer">
    <div class="footerContainer">
      <div class="footerLeft">
        <div>
          <strong class="companyLabel">(주) 소프트마니 </strong>

          <div class="secondFooterLabel">
            <strong class="pr-2">대표이사 : 이지현 </strong>
            <strong class="pr-2"
              >주소 : 서울시 강남구 역삼로 164 라비빌딩, 2F
            </strong>
            <strong class="pr-2">사업자등록번호 :732-81-01842 </strong>
            <strong>대표번호 : 1660-1866</strong>
          </div>
        </div>
        <div>
          <strong
            ><a
              class="linkLabel"
              href="https://precious-owl-430.notion.site/2024-02-27-61eda65d713f4f499f87bb821e7b81f6?pvs=4"
              target="_blank"
              >서비스 이용약관</a
            >
          </strong>
          |
          <strong
            ><a
              class="linkLabel"
              href="https://precious-owl-430.notion.site/2024-02-27-ac4e7c03d363421d98bcddab56020ea1?pvs=4"
              target="_blank"
              >개인정보처리방침
            </a></strong
          >
          <!-- <strong class="copyright">ⓒSoftMani all rights reserved. </strong> -->
        </div>
      </div>

      <div class="footerRight">
        <div class="Announcement">
          <div class="AnnouncementBody">
            <Announcement class="text-center subtitle-1" />
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import Announcement from "@/components/pages/common/Announcement.vue";
export default {
  components: {
    Announcement,
  },
};
</script>
